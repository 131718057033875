<template>
  <v-container class="col-9">
    <v-card>
      <v-card-title>Jobs</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Filename</th>
                <th class="text-left">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="job in jobs" :key="job.id">
                <td>{{ job.id }}</td>
                <td>{{ job.filename }}</td>
                <td>{{ job.status }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Jobs",
  data() {
    return {
      jobs: null,
    };
  },
  mounted() {
    axios
      .get(this.$api_base_url + "/job")
      .then((response) => (this.jobs = response.data.jobs));
  },
};
</script>